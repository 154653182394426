<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" aria-hidden="true">
    <g id="Icona_risparmio" transform="translate(-716 -1014)">
      <rect id="Icon_fin_spacer" width="48" height="48" transform="translate(716 1014)" fill="none"/>
      <g id="Icona_cofidis_risparmio" transform="translate(715.71 1014.12)">
        <path id="Path_8125" data-name="Path 8125" d="M45.82,38.25l-3.03,3.03v-8.4h-10v-10h-10v-10h-10v-10H1.29V5.12h9.25v10h10v10h10v10h10v6.16l-3.03-3.03-1.59,1.59,5.75,5.75,5.74-5.75Z" fill="#252525"/>
        <path id="Path_8126" data-name="Path 8126" d="M14.3,26.23c-.23-.04-.47-.08-.71-.11l-.34-.05H12.03l-.37.05c-.27.04-.55.08-.82.13a9.126,9.126,0,0,0-6.99,6.39H2.45a.587.587,0,0,0-.59.39c-.12.31-.26.61-.39.92l-.15.35-.03.56H3.5v.71H2.86a.353.353,0,0,1-.14-.01.96.96,0,0,0-.63.1.922.922,0,0,0-.33.54c-.02.05-.03.09-.05.14l-.38.88-.03.56H3.86a8.246,8.246,0,0,0,.82,1.93,9.093,9.093,0,0,0,6.58,4.54c.19.03.38.05.57.08l.31.04h1.11l.54-.07a8.971,8.971,0,0,0,4.58-1.94.681.681,0,0,0,.25-.51c.01-.49.01-.97,0-1.45v-.29a1,1,0,0,0-.02-.16l-.1-.77-.54.56a6.917,6.917,0,0,1-7.6,2.03A6.647,6.647,0,0,1,6.2,37.8h9.1a.512.512,0,0,0,.44-.29c.17-.39.33-.79.5-1.2l.3-.73H5.72v-.71h10.3a.57.57,0,0,1,.13.01.883.883,0,0,0,.62-.1.987.987,0,0,0,.33-.53.694.694,0,0,1,.05-.13l.61-1.47H6.2a6.639,6.639,0,0,1,3.89-3.86,6.906,6.906,0,0,1,7.89,1.96l.4.41.33-.79c.15-.37.31-.75.46-1.12a.556.556,0,0,0-.14-.57A9.089,9.089,0,0,0,14.3,26.23Z" fill="#252525"/>
      </g>
    </g>
  </svg>
</template>

<style scoped></style>
